import {
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  IconButton,
  Input,
  InputAdornment,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import {
  KeyboardArrowDown as ArrowDownIcon,
  KeyboardArrowUp as ArrowUpIcon,
  ChevronRight as ChevronRightIcon,
  MoreVert as MoreVertIcon,
} from '@material-ui/icons'
import { formatCurrency, formatDate, formatLocalizedDate } from '@omnicar/sam-format'
import { getLocale } from '@omnicar/sam-translate'
import {
  IContractCalculationRequest,
  IContractTemplateResponse,
  ICustomContractCalculationRequest,
  IsoLocale,
  IStandardAxContractCalculationRequest,
  IStandardContractCalculationRequest,
  IStandardV4PricingToolContractCalculationRequest,
  PaymentGateway,
  PriceSource,
  UserRole,
  VehicleAlongItsContracts,
} from '@omnicar/sam-types'
import { SentryEvent, Severity } from '@sentry/types'
import { calculateContractOffer } from 'api/api'
import classNames from 'classnames'
import { IReduxHiddenVATProps, withHiddenVAT } from 'components/ContractDisplayConfig/withHiddenVAT'
import LabelIncludingVat from 'components/Mui/Label/IncludingVat'
import { LayoutActionsRight } from 'components/Mui/Layout'
import Paper from 'components/Mui/Paper'
import Typography from 'components/Typography'
import { CONFIG } from 'config'
import debounce from 'lodash.debounce'
import isEqualWith from 'lodash.isequalwith'
import pick from 'lodash.pick'
import React, { ChangeEvent } from 'react'
import { compose } from 'recompose'
import { contractFlowInitialState } from 'reducers/contractFlow/contractFlowInitialState'
import { AppContext } from 'store/appContext'
import { createTypographyStyles, theme as customTheme } from 'theme'
import { t, tCurrency } from 'translations/translationFunctions'
import { ContractFlowActivePanel, ContractFlowType, IContractFlow, ICustomPrice } from 'types/contractFlow'
import { isPlainServiceSubscriptionContract } from 'utils/contract'
import { getCurrency } from 'utils/localStorage'
import { debugPrint } from 'utils/miscs'
import { zeroOrNumber } from 'utils/regex'
import { captureEvent } from 'utils/sentry'
import { encloseInParenthesis } from 'utils/string'
import { vehicleToVehicleInfo } from 'utils/vehicle'

export const IS_DEBUG_CALCULATE_PRICE_PRINT: boolean = false // Note: 'false' is the default.

interface IOwnProps {
  userRole: UserRole
  freeContract: boolean
  flow: ContractFlowType
  hasVehicleRequiredInfo?: boolean
  prettyIdentifier: string | undefined
  contract: IContractFlow
  onChange: (contract: Partial<IContractFlow>) => void
  readyForCalculation: () => boolean
  paymentGateways: PaymentGateway[]
  allowNext: boolean
  onNext: () => void
  locale: string
}

type TProps = WithStyles<typeof styles> & IOwnProps & IReduxHiddenVATProps

const styles = (theme: Theme) =>
  createTypographyStyles(theme, {
    root: {
      padding: 0,
      fontSize: 14,
    },
    rootInactive: {
      opacity: 0.4,
    },
    label: {
      display: 'flex',
      flexBasis: '100%',
    },
    action: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    col: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(2),
    },
    colWithIcon: {
      marginBottom: theme.spacing(1),
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: theme.spacing(7),
    },
    rowWithIcon: {
      paddingRight: 0,
    },
    graySection: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.grey[200],
    },
    whiteSection: {
      padding: theme.spacing(2),
    },
    amountPrPayment: {
      paddingBottom: 0,
      textAlign: 'right',
    },
    amountPrPaymentTitle: {
      color: theme.palette.primary.main,
    },
    amountPrPaymentField: {
      color: theme.palette.secondary.main,
      '&:before': {
        borderBottomColor: theme.palette.secondary.main,
      },
      '&:after': {
        borderBottomColor: theme.palette.secondary.main,
      },
    },
    amountPrPaymentFieldFreeContract: {
      color: customTheme.palette.text.dark,
      '&:before': {
        borderBottomColor: customTheme.palette.freeContract[500],
      },
      '&:after': {
        borderBottomColor: customTheme.palette.freeContract[500],
      },
    },
    previousContractFullCost: {},
    adjustedContractFullCost: {},
    adjustedPaymentsLeft: {},
    customerPayments: {},
    adjustedAmount: {},
    focusText: {
      fontSize: 34,
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
    firstPaymentDate: {},
    firstAmount: {},
    downpayment: {},
    totalCost: {},
    calculusHeader: {
      marginBottom: theme.spacing(2),
    },
    secondRowLabel: {
      whiteSpace: 'pre-wrap',
      textAlign: 'right',
      color: customTheme.palette.text.light,
      width: '180px',
    },
    rowLabel: {
      whiteSpace: 'nowrap',
      color: customTheme.palette.text.light,
    },
    rowInput: {
      textAlign: 'right',
      width: 200,
    },
    button: {
      marginBottom: theme.spacing(2),
    },
    icon: {
      fontSize: 16,
      color: customTheme.palette.text.light,
    },
    iconWithText: {
      marginRight: theme.spacing(2),
    },
    datePicker: {
      width: 350,
    },
    datePickerIcon: {
      top: '-3px',
      fontSize: 14,
    },
    discount: {
      marginTop: theme.spacing(2),
    },
    error: {
      color: customTheme.palette.context.warning[500],
      textAlign: 'right',
      paddingRight: theme.spacing(7),
    },
    iconButton: {
      marginBottom: 0,
    },
    discountButton: {
      top: '-3px',
      padding: '16px',
    },
    spinner: {
      position: 'absolute',
      top: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: 1000,
    },
    amountPrPaymentExVat: {
      color: theme.palette.grey[700],
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: 12,
      justifyContent: 'flex-end',
    },
    iconChevronRight: {
      fontSize: 14,
      marginLeft: theme.spacing(1),
    },
    nextButton: {
      marginTop: '1.6em',
    },
    hiddenVisibility: {
      visibility: 'hidden',
    },
    containerIconMoreVert: {},
    iconMoreVert: {
      fontSize: '0.9em',
      margin: theme.spacing(1),
    },
    iconArrowUpDown: {
      fontSize: '1.1em',
    },
    dummy: {
      padding: '22.5px',
    },
  })

interface IState {
  errors: {
    providerPayments: string
    providerShare: string
  }
  discount: boolean
  loading: boolean
  downPaymentAnchorEl: HTMLElement | null
  isDownpaymentDistributed: boolean
  providerShare: number
}

const getFormattedPrice = (price?: number): string | number => (price ? formatCurrency(price) : 0)

class ContractFlowPayments extends React.Component<TProps, IState> {
  private locale: IsoLocale = 'da-DK'
  private currency: string = ''
  private lastStartedCalculatePrice: number = new Date().getTime()
  private plannedCalculatePrice: boolean = false
  private lastSentCalcRequest: IContractCalculationRequest | null = null

  constructor(props: TProps) {
    super(props)
    this.state = {
      errors: {
        providerPayments: '',
        providerShare: '',
      },
      discount: false,
      loading: false,
      downPaymentAnchorEl: null,
      isDownpaymentDistributed: false,
      providerShare: props.contract.payments.providerShare.priceInclVat,
    }
  }

  public componentDidMount() {
    if (!this.currency) {
      this.currency = getCurrency()
    }
    this.triggerCalculatePrice()
    this.locale = getLocale() || this.locale
  }

  public async componentDidUpdate(prevProps: TProps) {
    const { props } = this
    // If template, options, duration, mileage, vehicle, payments from value
    // has changed we also update
    const prev = pick(prevProps.contract, [
      'template',
      'options',
      'duration',
      'mileage',
      'contractStartDateISO',
      'durationValues',
      'product',
      'payments',
      'startMileage', // This ignored here, done futher down.
      'startValue',
      'valueType',
      'startValueType',
    ])
    const current = pick(props.contract, [
      'template',
      'options',
      'duration',
      'mileage',
      'contractStartDateISO',
      'durationValues',
      'product',
      'payments',
      'startMileage', // This ignored here, done futher down.
      'startValue',
      'valueType',
      'startValueType',
    ])

    if (!isEqualWith(prev, current)) {
      debugPrint(IS_DEBUG_CALCULATE_PRICE_PRINT, '*About to trigger CalculatePrice*')
      await this.debouncedTriggerCalculatePrice()
    }

    if (
      this.state.discount &&
      !(props.contract.state.active >= ContractFlowActivePanel.durationMileage) &&
      !props.freeContract
    ) {
      this.setState({ discount: false })
    }

    if (props.contract.duration !== prevProps.contract.duration) {
      // The provider payments should have been adjusted to a valid value
      // Turn off any errors
      this.setState({
        errors: { ...this.state.errors, providerPayments: '' },
      })
    }

    const newLocale = getLocale()
    if (newLocale && newLocale !== this.locale) {
      this.locale = newLocale
      this.updateErrorTranslations()
    }
  }

  public shouldComponentUpdate(nextProps: TProps, nextState: IState) {
    const { props, state } = this

    if (nextProps.userRole !== props.userRole) {
      return true
    }

    if (nextState.discount !== state.discount || nextState.downPaymentAnchorEl !== state.downPaymentAnchorEl) {
      return true
    }

    if (nextProps.freeContract !== props.freeContract) {
      return true
    }

    // If template, options, duration, mileage, vehicle, payments from value
    // has changed we also update
    const nextValue = pick(nextProps.contract, [
      'flowType',
      'template',
      'options',
      'duration',
      'mileage',
      'contractStartDateISO',
      'value',
      'valueType',
      'product',
      'payments',
      'startMileage',
      'startValue',
      'startValueType',
    ])
    const currentValue = pick(props.contract, [
      'flowType',
      'template',
      'options',
      'duration',
      'mileage',
      'contractStartDateISO',
      'product',
      'payments',
      'startMileage',
      'value',
      'valueType',
      'startValue',
      'startValueType',
    ])

    if (!isEqualWith(nextValue, currentValue)) {
      if (
        nextState.providerShare === this.state.providerShare &&
        nextValue.payments.providerShare.priceInclVat !== currentValue.payments.providerShare.priceInclVat &&
        nextValue.flowType === currentValue.flowType
      ) {
        return false
      }
      return true
    }

    if (nextProps.contract.state.active !== props.contract.state.active) {
      return true
    }

    return true
  }

  public render() {
    const { errors } = this.state
    const {
      classes,
      freeContract,
      contract,
      flow,
      hasVehicleRequiredInfo,
      paymentGateways,
      allowNext,
      onNext,
      hiddenVAT,
    } = this.props
    const localRawFormat: string = 'dd MMM yyyy'
    const { discount, loading, providerShare } = this.state
    const { payments } = contract
    const { providerPayments } = payments

    const amountPrPayment = getFormattedPrice(payments.amountPrPayment.priceInclVat)
    const downpayment = getFormattedPrice(payments.downpayment.priceInclVat)
    const distributedDownpayment = getFormattedPrice(payments.distributedAmount.priceInclVat)

    // disable next step for custom contract when amountPrPayment === 0 or when doing free search
    let disableNextStep: boolean =
      (contract.contractType === 'CUSTOM' && !payments.amountPrPayment.priceInclVat) || contract.state.licenseSkip
    if (!payments.amountPrPayment.priceInclVat || !payments.contractCost.priceInclVat) {
      disableNextStep = true
    }

    const firstPaymentDate = contract ? payments.firstPaymentDate : ''
    const amountPrPaymentExVat = getFormattedPrice(payments.amountPrPayment.price)
    const providerCost = getFormattedPrice(hiddenVAT ? payments.providerCost.priceInclVat : payments.providerCost.price)
    const customerCost = getFormattedPrice(payments.customerCost.priceInclVat)
    const contractCost = getFormattedPrice(payments.contractCost.priceInclVat)
    const adjustedContractFullCost = getFormattedPrice(
      payments.adjustedContractFullCost ? payments.adjustedContractFullCost.priceInclVat : 0,
    )
    const previousContractPrice =
      contract.previousContractPrice && formatCurrency(contract.previousContractPrice.priceInclVat)
    const adjustedAmount = formatCurrency(payments.adjustedAmount?.priceInclVat || 0)
    const isDisabled = freeContract ? false : !(contract.state.active >= ContractFlowActivePanel.durationMileage)
    const discountDisabled = isDisabled || payments.noPayment
    const providerPaymentsMax = contract.duration.value - 1

    // NOTE: "No payment" <=> "100% discount" <=> "100% dealer paid"
    const noPaymentsSupported = paymentGateways.includes('NONE')

    // The group with "Provider payment discount", "Provider share discount", etc..
    const isShowDiscountOptions: boolean =
      contract.template?.priceSource !== 'V4PricingTool' && !contract.state.licenseSkip

    const isDealerPaid: boolean = contract.v4SupportedPaymentTypes.includes('DealerPaid')

    const isCustomerSubscription: boolean =
      contract.v4SupportedPaymentTypes.length === 0 || contract.v4SupportedPaymentTypes.includes('CustomerSubscription')

    /* NOTE:
     * - On Warranty Subscriptions (contract with a warranty option):
     *    - The "100% discount option" should NOT be visible,
     *    - Instead the notice "Use Dealer Paid Warranty button at top right" should be shown.
     * - On (normal, with no warranty) Service Subscriptions:
     *    - The "100% discount option" should be visible.
     */
    const isShowNoPaymentsAka100Discount = noPaymentsSupported && isPlainServiceSubscriptionContract(contract)

    return (
      <div className={classes.root}>
        {loading && <CircularProgress className={classes.spinner} size={24} style={{ color: '#111111' }} />}
        <Paper
          className={classNames(
            !contract && !freeContract && !hasVehicleRequiredInfo && classes.rootInactive,
            (isDisabled || loading) && classes.rootInactive,
          )}
          data-e2e={'ContractFlowPayments'}
        >
          <section className={classNames(classes.amountPrPayment, classes.col, classes.whiteSection)}>
            {isDealerPaid && (
              <div className={classes.row}>
                <Typography className={classes.subtitle} variant="subtitle">
                  {t('Dealer payment')}
                </Typography>
              </div>
            )}
            {isDealerPaid && (
              <div className={classNames(classes.row, classes.focusText, classes.rowWithIcon)}>
                <Typography className={classes.amountPrPaymentTitle} variant="header" tag="span">
                  {t('Total price')}:
                </Typography>
                <div>
                  <Typography
                    className={classes.amountPrPaymentTitle}
                    variant="header"
                    tag="span"
                    data-e2e={'ContractFlowPayments__amountPrPayment'}
                  >
                    <span title={this.getContractPriceTooltipText()}>{`${contractCost} ${tCurrency(
                      payments.amountPrPayment.currency || this.currency,
                    )}`}</span>
                  </Typography>
                  <span
                    className={classNames(
                      classes.button,
                      classes.discountButton,
                      classes.iconButton,
                      classes.icon,
                      classes.iconArrowUpDown,
                      classes.dummy,
                    )}
                  >
                    &nbsp;
                  </span>
                </div>
              </div>
            )}
            {!hiddenVAT && isDealerPaid && (
              <div className={classNames(classes.row, classes.amountPrPaymentExVat)}>{`${formatCurrency(
                payments.contractCost.price,
              )} ${tCurrency(payments.amountPrPayment.currency || this.currency)} ${t('ex. VAT')}`}</div>
            )}
            {isCustomerSubscription && (
              <div className={classes.row}>
                <Typography className={classes.subtitle} variant="subtitle">
                  {t('Customer subscription')}
                </Typography>
              </div>
            )}
            {isCustomerSubscription && (
              <div className={classNames(classes.row, classes.focusText, classes.rowWithIcon)}>
                <Typography className={classes.amountPrPaymentTitle} variant="header" tag="span">
                  {t('Price per month')}:
                </Typography>
                <div>
                  <Typography
                    className={classes.amountPrPaymentTitle}
                    variant="header"
                    tag="span"
                    data-e2e={'ContractFlowPayments__amountPrPayment'}
                  >
                    <span title={this.getContractPriceTooltipText()}>{`${amountPrPayment} ${tCurrency(
                      payments.amountPrPayment.currency || this.currency,
                    )}`}</span>
                  </Typography>
                  {isShowDiscountOptions ? (
                    <AppContext.Consumer>
                      {({ providerInfo }) =>
                        !providerInfo?.isUsingV4PricingTool && !providerInfo?.isUseV4PTOnlyForSigning ? (
                          <IconButton
                            className={classNames(
                              classes.button,
                              classes.discountButton,
                              classes.iconButton,
                              providerInfo && providerInfo.parentProviderId && classes.hiddenVisibility,
                            )}
                            onClick={this.handleDiscountClick}
                            data-e2e={'PaymentsColumn__discount'}
                            disabled={isDisabled}
                          >
                            {discount ? (
                              <ArrowUpIcon className={classNames(classes.icon, classes.iconArrowUpDown)} />
                            ) : (
                              <ArrowDownIcon className={classNames(classes.icon, classes.iconArrowUpDown)} />
                            )}
                          </IconButton>
                        ) : (
                          <span
                            className={classNames(
                              classes.button,
                              classes.discountButton,
                              classes.iconButton,
                              classes.icon,
                              classes.iconArrowUpDown,
                              classes.dummy,
                            )}
                          >
                            &nbsp;
                          </span>
                        )
                      }
                    </AppContext.Consumer>
                  ) : (
                    <span
                      className={classNames(
                        classes.button,
                        classes.discountButton,
                        classes.iconButton,
                        classes.icon,
                        classes.iconArrowUpDown,
                        classes.dummy,
                      )}
                    >
                      &nbsp;
                    </span>
                  )}
                </div>
              </div>
            )}

            {!hiddenVAT && isCustomerSubscription && (
              <div className={classNames(classes.row, classes.amountPrPaymentExVat)}>
                {encloseInParenthesis(
                  `${amountPrPaymentExVat} ${tCurrency(payments.amountPrPayment.currency || this.currency)} ${t(
                    'ex. VAT',
                  )}`,
                )}
              </div>
            )}
          </section>
          <AppContext.Consumer>
            {({ providerInfo }) =>
              isShowDiscountOptions &&
              !providerInfo?.isUsingV4PricingTool &&
              !providerInfo?.isUseV4PTOnlyForSigning && (
                <Collapse in={discount}>
                  <section className={classNames(classes.graySection, classes.discount, classes.col)}>
                    <section className={classNames(classes.col)}>
                      <div className={classes.row}>
                        <span className={classes.rowLabel}>{t('Provider payment discount')}:</span>
                        <Input
                          disabled={discountDisabled}
                          className={classes.rowInput}
                          name="providerPayments"
                          value={providerPayments > 0 ? providerPayments : ''}
                          endAdornment={<InputAdornment position="end">{t('Months/Payments')}</InputAdornment>}
                          onChange={this.handlePaymentsChange}
                          type="number"
                          inputProps={{
                            max: providerPaymentsMax || 0,
                            style: {
                              textAlign: 'right',
                            },
                          }}
                        />
                      </div>
                      {errors && errors.providerPayments && errors.providerPayments.length && (
                        <Typography variant="caption" className={classes.error}>
                          {errors.providerPayments}
                        </Typography>
                      )}
                    </section>
                    <section className={classNames(classes.col)}>
                      <div className={classes.row}>
                        <span className={classes.rowLabel}>{t('Provider share discount')}:</span>
                        <Input
                          disabled={discountDisabled}
                          className={classes.rowInput}
                          name="providerShare"
                          value={providerShare > 0 ? providerShare : ''}
                          endAdornment={
                            <InputAdornment position="end">{`${tCurrency(
                              payments.amountPrPayment.currency || this.currency,
                            )}`}</InputAdornment>
                          }
                          onChange={this.handleShareChange}
                          type="number"
                          inputProps={{
                            max: payments.contractCost.priceInclVat,
                            size: 4,
                            style: {
                              textAlign: 'right',
                            },
                          }}
                        />
                      </div>
                      {errors && errors.providerShare && errors.providerShare.length && (
                        <Typography variant="caption" className={classes.error}>
                          {errors.providerShare}
                        </Typography>
                      )}
                    </section>
                    {
                      /* Do not show "100% discount" for Warranty Contracts */
                      !isShowNoPaymentsAka100Discount && (
                        <section className={classNames(classes.col, classes.colWithIcon)}>
                          <div className={classes.row}>
                            <span className={classes.rowLabel}>
                              {t('Looking to sign a 100% dealer paid warranty instead?')}
                            </span>
                            <span className={classes.secondRowLabel}>
                              {t('Click the "Dealer Paid Warranty" button at top right')}
                            </span>
                          </div>
                        </section>
                      )
                    }
                    {
                      /* Show "100% discount" only for (plain) Service Contracts */
                      isShowNoPaymentsAka100Discount && (
                        <section className={classNames(classes.col, classes.colWithIcon)}>
                          <div className={classes.row}>
                            <span className={classes.rowLabel}>{t('100% discount (no charge)')}</span>
                            <Checkbox
                              disabled={isDisabled}
                              checked={contract.payments.noPayment}
                              onChange={this.noPaymentsAka100DiscountChange}
                              value="no-payment"
                              name="no-payment"
                            />
                          </div>
                        </section>
                      )
                    }
                    <section className={classNames(classes.col)}>
                      <div className={classes.row}>
                        <span className={classes.rowLabel}>{t('Provider cost')}:</span>
                        <span data-e2e="ContractFlowPayments__providerCost">{`${providerCost} ${tCurrency(
                          payments.providerCost.currency || this.currency,
                        )} ${hiddenVAT ? '' : t('ex. VAT')}`}</span>
                      </div>
                    </section>
                    <section className={classNames(classes.col)}>
                      <div className={classes.row}>
                        <span className={classes.rowLabel}>{t('Customer cost')}:</span>
                        <span data-e2e="ContractFlowPayments__customerCost">{`${customerCost} ${tCurrency(
                          payments.customerCost.currency || this.currency,
                        )}`}</span>
                      </div>
                    </section>
                  </section>
                </Collapse>
              )
            }
          </AppContext.Consumer>
          {freeContract && (
            <section className={classes.whiteSection}>
              <div className={classes.row}>
                <span className={classes.rowLabel}>{t('Own custom price (pr month)')}:</span>
                <Input
                  disabled={isDisabled}
                  name="amountPrPayment"
                  className={classes.rowInput}
                  classes={{
                    underline: freeContract ? classes.amountPrPaymentFieldFreeContract : classes.amountPrPaymentField,
                  }}
                  value={payments.customAmountPrPayment > 0 ? payments.customAmountPrPayment : ''}
                  endAdornment={
                    <InputAdornment position="end">{payments.amountPrPayment.currency || this.currency}</InputAdornment>
                  }
                  onChange={this.handleAmountPrPaymentChange}
                  type="number"
                  inputProps={{
                    style: {
                      textAlign: 'right',
                    },
                  }}
                />
              </div>
            </section>
          )}
          <section className={freeContract ? classes.graySection : classes.whiteSection}>
            <section
              className={classNames(
                classes.downpayment,
                classes.col,
                contract.flowType !== 'ADJUST' ? classes.colWithIcon : '',
              )}
            >
              <AppContext.Consumer>
                {({ providerInfo }) =>
                  !providerInfo?.isUsingV4PricingTool &&
                  !providerInfo?.isUseV4PTOnlyForSigning && (
                    <div className={classNames(classes.row, contract.flowType !== 'ADJUST' ? classes.rowWithIcon : '')}>
                      {!payments.isDownpaymentDistributed ? (
                        <span className={classes.rowLabel}>{t('Downpayment total')}:</span>
                      ) : (
                        <span className={classes.rowLabel}>{t('Distributed downpayment')}:</span>
                      )}
                      <span data-e2e={'ContractFlowPayments__downPayment'}>
                        {!payments.isDownpaymentDistributed ? (
                          <span>{`${downpayment} ${tCurrency(payments.downpayment.currency || this.currency)}`}</span>
                        ) : (
                          <span>{`${distributedDownpayment} ${tCurrency(
                            payments.distributedAmount.currency || this.currency,
                          )}`}</span>
                        )}
                        {contract.flowType !== 'ADJUST' && (
                          <IconButton
                            className={classNames(classes.containerIconMoreVert)}
                            aria-label="Downpayment options"
                            aria-owns={this.state.downPaymentAnchorEl ? 'down-payment-options' : undefined}
                            aria-haspopup="true"
                            disabled={isDisabled || (contract.template && contract.template.calculationMethod !== 100)}
                            onClick={this.handleDownPaymentIconClick}
                            data-e2e={'ContractFlowPayments__downPayment-button'}
                          >
                            <MoreVertIcon
                              className={classNames(classes.iconMoreVert)}
                              visibility={
                                isDisabled || (contract.template && contract.template.calculationMethod !== 100)
                                  ? 'hidden'
                                  : 'visible'
                              }
                            />
                          </IconButton>
                        )}
                      </span>
                      <Menu
                        id="down-payment-options"
                        anchorEl={this.state.downPaymentAnchorEl}
                        open={Boolean(this.state.downPaymentAnchorEl)}
                        onClose={this.handleDownPaymentClose}
                        PaperProps={{
                          style: {
                            maxHeight: 500,
                            width: 300,
                          },
                        }}
                      >
                        <MenuItem
                          data-distributedownpayment={'false'}
                          onClick={this.changeDownPaymentMetod}
                          selected={!payments.isDownpaymentDistributed}
                          data-e2e={'ContractFlowPayments__downPayment-notdistributed'}
                        >
                          {t('Downpayment total')}:
                        </MenuItem>
                        <MenuItem
                          data-distributedownpayment={'true'}
                          onClick={this.changeDownPaymentMetod}
                          selected={payments.isDownpaymentDistributed}
                          data-e2e={'ContractFlowPayments__downPayment-distributed'}
                        >
                          {t('Distributed downpayment')}:
                        </MenuItem>
                      </Menu>
                    </div>
                  )
                }
              </AppContext.Consumer>
            </section>
            <AppContext.Consumer>
              {({ providerInfo }) =>
                !providerInfo?.isUsingV4PricingTool &&
                !providerInfo?.isUseV4PTOnlyForSigning && (
                  <section className={classNames(classes.totalCost, classes.col)}>
                    <div className={classes.row}>
                      <span className={classes.rowLabel}>{t('Total price')}:</span>
                      <span data-e2e={'ContractFlowPayments__totalPrice'}>{`${contractCost} ${tCurrency(
                        payments.downpayment.currency || this.currency,
                      )}`}</span>
                    </div>
                  </section>
                )
              }
            </AppContext.Consumer>
            <AppContext.Consumer>
              {({ providerInfo }) =>
                !providerInfo?.isUsingV4PricingTool &&
                !providerInfo?.isUseV4PTOnlyForSigning && (
                  <section className={classNames(classes.firstPaymentDate, classes.col)}>
                    <AppContext.Consumer>
                      {({ locale }) => (
                        <div className={classes.row}>
                          <span className={classes.rowLabel}>{t('First recurring payment')}:</span>
                          <span data-e2e={'ContractFlowPayments__firstPaymentDate'}>
                            {!firstPaymentDate
                              ? '-'
                              : `${formatLocalizedDate(new Date(firstPaymentDate), localRawFormat, locale)}`}
                          </span>{' '}
                        </div>
                      )}
                    </AppContext.Consumer>
                  </section>
                )
              }
            </AppContext.Consumer>
          </section>
          {flow === 'ADJUST' && (
            <section className={classes.whiteSection}>
              <Typography className={classes.calculusHeader} variant="title">
                {t('Intermediate result')}:
              </Typography>
              <section className={classes.adjustedContractFullCost}>
                <div className={classes.row}>
                  <span className={classes.rowLabel}>
                    {`${t(
                      contract.contractType === 'CUSTOM'
                        ? 'Selected total price for free contract'
                        : 'Original total price for selected contract',
                    )}:`}
                  </span>
                  <span data-e2e={'ContractFlowPayments__adjustedContractFullCost'}>{`${adjustedContractFullCost} ${
                    payments.adjustedContractFullCost
                      ? tCurrency(payments.adjustedContractFullCost.currency || this.currency)
                      : ''
                  }`}</span>
                </div>
              </section>
              <section className={classes.adjustedAmount}>
                <div className={classes.row}>
                  <span className={classes.rowLabel}>{t('Payed on current contract')}:</span>
                  <span data-e2e={'ContractFlowPayments__adjustedAmount'}>{`${adjustedAmount} ${
                    payments.adjustedAmount ? tCurrency(payments.adjustedAmount.currency || this.currency) : ''
                  }`}</span>
                </div>
              </section>
              <section className={classes.totalCost}>
                <div className={classes.row}>
                  <span className={classes.rowLabel}>{t('Difference')}:</span>
                  <span data-e2e={'ContractFlowPayments__totalPrice'}>{`${contractCost} ${tCurrency(
                    payments.downpayment.currency || this.currency,
                  )}`}</span>
                </div>
              </section>
              {contract.contractType === 'CUSTOM' && (
                <section className={classes.previousContractFullCost}>
                  <div className={classes.row}>
                    <span className={classes.rowLabel}>{t('Previous total price for selected contract')}:</span>
                    <span data-e2e={'ContractFlowPayments__previousContractFullCost'}>{`${previousContractPrice} ${
                      previousContractPrice ? tCurrency(contract.previousContractPrice?.currency || this.currency) : ''
                    }`}</span>
                  </div>
                </section>
              )}
              <section className={classes.adjustedPaymentsLeft}>
                <div className={classes.row}>
                  <span className={classes.rowLabel}>{t('Months left for selected contract')}:</span>
                  <span data-e2e={'ContractFlowPayments__adjustedPaymentsLeft'}>
                    {payments.adjustedPaymentsLeft ? payments.adjustedPaymentsLeft : ''}
                  </span>
                </div>
              </section>
            </section>
          )}
          <AppContext.Consumer>
            {({ providerInfo }) =>
              isCustomerSubscription &&
              !isDealerPaid &&
              !providerInfo?.isUsingV4PricingTool &&
              !providerInfo?.isUseV4PTOnlyForSigning && (
                <section className={freeContract ? classes.graySection : classes.whiteSection}>
                  <LabelIncludingVat locale={this.locale} />
                </section>
              )
            }
          </AppContext.Consumer>
        </Paper>
        <LayoutActionsRight className={classes.nextButton}>
          <Button
            className={classes.customerAndPaymentButton}
            onClick={onNext}
            color="primary"
            size="small"
            disabled={!allowNext || disableNextStep}
            variant="contained"
            data-e2e="PaymentsColumn__addCustomer"
          >
            {contract.flowType === 'CREATE' ? t('Customer & Payment') : t('Next')}
            <ChevronRightIcon className={classes.iconChevronRight} />
          </Button>
        </LayoutActionsRight>
      </div>
    )
  }

  private updateErrorTranslations = () => {
    const { errors } = this.state
    const { contract } = this.props
    const { payments } = contract

    let providerPayments = errors.providerPayments
    if (providerPayments) {
      const maxProviderPayments = contract.duration.value - 1

      if (payments.providerPayments === (maxProviderPayments || 0) && maxProviderPayments < 20) {
        providerPayments = t('Provider discount has to be less than the contract duration (%duration months)', {
          duration: contract.duration.value,
        })
      } else if (contract.paymentGateway === 'Stripe' && payments.providerPayments === 20) {
        // Stripe hard limit
        providerPayments = t('Provider discount can not be more than %max months', { max: 20 })
      }
    }

    let providerShare = errors.providerShare
    if (providerShare) {
      providerShare = t(
        'Provider discount can not be higher (%providerShareCost)',
        {
          providerShareCost: formatCurrency(payments.providerShare.priceInclVat, {
            symbolDisplayType: 'APPEND',
          }),
        },
        this.locale,
      )
    }

    this.setState({ errors: { providerPayments, providerShare } })
  }

  // Get prices and description for
  // Template Properties
  // Contract Options
  private getContractPriceTooltipText() {
    const { contract } = this.props

    let price: string = ''
    const maximumLength: number = 40

    if (contract.template) {
      contract.template.properties.forEach((item) => {
        price += `${this.truncate(item.description, maximumLength)} ${formatCurrency(
          item.price.priceInclVat,
        )} ${tCurrency(item.price.currency)} ${'\n'}`
      })
    }

    contract.options.forEach((item) => {
      price += `${this.truncate(item.description, maximumLength)} ${formatCurrency(
        item.price.priceInclVat,
      )} ${tCurrency(item.price.currency)} ${'\n'}`
    })

    return price
  }

  // Truncate text to max length
  private truncate(text: string, maximumLength: number) {
    return text.length > maximumLength ? text.substr(0, maximumLength - 1) + '...' : text
  }

  /**
   * Downpayment menu functions
   */

  private handleDownPaymentIconClick = (e: React.MouseEvent<HTMLElement>) => {
    this.setState({ downPaymentAnchorEl: e.currentTarget })
  }

  private handleDownPaymentClose = () => {
    this.setState({ downPaymentAnchorEl: null })
  }

  private changeDownPaymentMetod = (e: React.MouseEvent<HTMLElement>) => {
    const { contract } = this.props

    const distributedDownPayment = e.currentTarget.dataset.distributedownpayment === 'true'

    const payments: ICustomPrice = {
      ...contract.payments,
      isDownpaymentDistributed: distributedDownPayment,
    }

    if (contract.payments.isDownpaymentDistributed !== distributedDownPayment) {
      this.props.onChange({ payments })
    }

    this.handleDownPaymentClose()
  }

  private handleAmountPrPaymentChange = (e: ChangeEvent<HTMLInputElement>) => {
    const price = zeroOrNumber(e.target.value)
    const { payments } = this.props.contract

    this.props.onChange({
      payments: {
        ...payments,
        customAmountPrPayment: price,
      },
    })
  }

  private handleDiscountClick = () => {
    this.setState({ discount: !this.state.discount })
  }

  private handleShareChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { contract } = this.props

    // We don't allow very long inputs
    if (e.target.value.length > 10) {
      return
    }

    // ensure providerPayments is a number
    let providerShare = zeroOrNumber(e.target.value)

    this.setState({
      providerShare,
      errors: { ...this.state.errors, providerShare: '' }, // refresh providerShare error line after update
    })

    this.props.onChange({
      payments: {
        ...contract.payments,
        providerShare: {
          ...contract.payments.providerShare,
          priceInclVat: providerShare!,
          price: 0,
          vatShare: 0,
        },
      },
    })
  }

  private handlePaymentsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { contract } = this.props
    const errors = {
      ...this.state.errors,
      providerPayments: '',
    }

    // ensure providerPayments is a number
    let providerPayments = zeroOrNumber(e.target.value)
    if (providerPayments < 0) {
      // Don't update if value is not a number of if it's negative
      return
    }

    const maxProviderPayments = contract.duration.value - 1

    if (providerPayments > (maxProviderPayments || 0) && maxProviderPayments <= 20) {
      errors.providerPayments = t('Provider discount has to be less than the contract duration (%duration months)', {
        duration: contract.duration.value,
      })

      providerPayments = maxProviderPayments
    } else if (contract.paymentGateway === 'Stripe' && providerPayments > 20) {
      // Stripe hard limit
      errors.providerPayments = t('Provider discount can not be more than %max months', { max: 20 })
      providerPayments = 20
    }

    this.setState({ errors }, () => {
      this.props.onChange({
        payments: {
          ...contract.payments,
          providerPayments: providerPayments!,
        },
      })
    })
  }

  private noPaymentsAka100DiscountChange = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    const { contract, paymentGateways } = this.props
    const payments: ICustomPrice = {
      ...contract.payments,
      noPayment: checked,
    }
    let paymentGateway: PaymentGateway

    if (checked) {
      payments.providerPayments = contract.duration.value
      payments.providerShare = { ...payments.providerShare, priceInclVat: 0 }
      paymentGateway = 'NONE'
      // eslint-disable-next-line
      this.state.errors.providerPayments = ''
      // eslint-disable-next-line
      this.state.errors.providerShare = ''
    } else {
      // We default back to the first non-NONE paymentGateway and assume there will be at least 1
      paymentGateway = paymentGateways.filter((p) => p !== 'NONE')[0]
      payments.providerPayments = 0
      payments.providerShare = { ...payments.providerShare, priceInclVat: 0 }
    }

    this.props.onChange({ payments, paymentGateway })
  }

  private loadData = async (
    request:
      | IStandardContractCalculationRequest
      | ICustomContractCalculationRequest
      | IStandardAxContractCalculationRequest
      | IStandardV4PricingToolContractCalculationRequest,
  ) => {
    debugPrint(IS_DEBUG_CALCULATE_PRICE_PRINT, '..loadData')
    if (isEqualWith(this.lastSentCalcRequest, request)) {
      console.warn('*got request from buffer*')
      debugPrint(IS_DEBUG_CALCULATE_PRICE_PRINT, '..bailed loadData')
      return
    }

    const { contract, onChange } = this.props
    const errors = {
      ...this.state.errors,
      providerShare: '',
    }
    const currentProviderShare = contract.payments.providerShare

    this.setState({ loading: true })
    this.lastSentCalcRequest = request

    debugPrint(IS_DEBUG_CALCULATE_PRICE_PRINT, '-> calculateContractOffer.. make request')
    const response = await calculateContractOffer(request)

    // if (response?.statusCode === 499) {
    //   // calculateContractOffer failed!
    //   notify.error({ message: response?.errorData?.message })
    //   // Send error log to Sentry (only in staging/production).
    //   const env = CONFIG.env
    //   const errorEventToSentry: SentryEvent = {
    //     message:
    //       'Received error from response from calculateContractOffer(request) in loadData(..) in class ContractFlowPayments',
    //     extra: {
    //       statusCode: response?.statusCode,
    //       message: response?.errorData?.message,
    //     },
    //     level: Severity.Error,
    //     environment: env,
    //   }
    //   if (env === 'production' || env === 'staging') {
    //     captureEvent(errorEventToSentry)
    //   } else {
    //     console.error(errorEventToSentry)
    //   }
    //   if (env != 'production') {
    //     console.error('response:')
    //     console.error(response)
    //   }
    //
    // throw Error('GENERIC_ERROR')
    //  //return
    //}

    this.setState({ loading: false })

    if (
      response &&
      response.data &&
      (response.data as any).message &&
      (response.data as any).message === 'MODELS_NO_MODELS'
    ) {
      console.warn('MODELS_NO_MODELS')
    } else if (response && response.data) {
      // We exclude providerPayments and providerShare because the endpoint doesn't change those
      // If we don't exclude them, the input might jump
      const { providerPayments, providerShare, serviceInfo, ...payments } = response.data
      let invalidPoviderShare = false

      serviceInfo && this.props.onChange({ serviceInfo: serviceInfo })

      if (providerShare.priceInclVat !== currentProviderShare.priceInclVat) {
        invalidPoviderShare = true
        // update providerShare state to the maximum amount
        this.setState({ providerShare: providerShare.priceInclVat })
        // error message if provider payments higher than contract price
        errors.providerShare = t('Provider discount can not be higher (%providerShareCost)', {
          providerShareCost: formatCurrency(providerShare.priceInclVat, {
            symbolDisplayType: 'APPEND',
          }),
        })
        this.setState({ errors })
      }

      const parsedContractStartDateISO: Date | null = !response?.data?.contractStartDate
        ? null
        : new Date(response.data.contractStartDate)

      if (request.type === 'CUSTOM') {
        onChange({
          contractStartDateISO: parsedContractStartDateISO,
          payments: {
            ...contract.payments,
            ...payments,
            providerShare: invalidPoviderShare ? providerShare : currentProviderShare,
            customAmountPrPayment: contract.payments.customAmountPrPayment,
          },

          invalidPaymentData: false,
        })
      } else if (request.type === 'STANDARD') {
        onChange({
          contractStartDateISO: parsedContractStartDateISO,
          payments: {
            ...contract.payments,
            ...payments,
            providerShare: invalidPoviderShare ? providerShare : currentProviderShare,
          },
          invalidPaymentData: false,
        })
      }
    } else {
      onChange({
        payments: { ...contractFlowInitialState.payments },
        invalidPaymentData: true,
      })
      if (!response.statusCode || response.statusCode !== 499) {
        this.lastSentCalcRequest = null
      }
    }
  }

  private delay = async (ms: number): Promise<any> => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  private triggerCalculatePrice = async () => {
    debugPrint(IS_DEBUG_CALCULATE_PRICE_PRINT, '\n--------------------------')
    debugPrint(IS_DEBUG_CALCULATE_PRICE_PRINT, 'triggered wish to request CalculatePrice')
    debugPrint(
      IS_DEBUG_CALCULATE_PRICE_PRINT,
      `*Wish request CalculatePrice* newStartMileage: ${this.props.contract.startMileage}`,
    )
    debugPrint(
      IS_DEBUG_CALCULATE_PRICE_PRINT,
      `selectedTemplate: "${this.props.contract.template?.description}", payments.amountPrPayment: ${
        this.props.contract.payments.amountPrPayment.priceInclVat / 100
      }`,
    )
    debugPrint(IS_DEBUG_CALCULATE_PRICE_PRINT, '--------------------------\n')

    const ms = 300 // Hmm.. this delays the calculation, or what is the purpose (why?), is this really needed anymore? -marko

    const now: number = new Date().getTime()
    if (now - this.lastStartedCalculatePrice > ms) {
      this.lastStartedCalculatePrice = now
      this.calculatePrice()
      return
    }

    if (this.plannedCalculatePrice) {
      return
    }

    this.plannedCalculatePrice = true
    await this.delay(this.lastStartedCalculatePrice + ms - now)

    this.lastStartedCalculatePrice = new Date().getTime()
    this.plannedCalculatePrice = false
    this.calculatePrice()
  }

  private debouncedTriggerCalculatePrice = debounce(this.triggerCalculatePrice, 350)

  private calculatePrice = () => {
    debugPrint(IS_DEBUG_CALCULATE_PRICE_PRINT, 'DO about to calculatePrice')

    const { freeContract, contract, flow, prettyIdentifier, readyForCalculation, userRole } = this.props
    const {
      template,
      options,
      duration,
      mileage,
      contractStartDateISO,
      product,
      payments,
      startMileage,
      startValue,
      startValueType,
      valueType,
      value,
    } = contract

    const { providerPayments, providerShare, isDownpaymentDistributed } = payments
    const isMileageDisabled: boolean = !!contract.state.isMileageDisabled

    let baseRequest: IContractCalculationRequest | null = null
    try {
      const realValue = value ? value.value : value
      const vin: string = (product as any).vin
      const parsedStartDateISOString = !contractStartDateISO ? '' : new Date(contractStartDateISO).toISOString() // NOTE: Important to call toISOString() on a created Date object.

      console.debug('\ncalculatePrice:--------------')
      console.debug('--------------\n')
      console.debug('payments?.contractStartDate =' + payments?.contractStartDate)
      console.debug('   parsedStartDateISOString =' + parsedStartDateISOString)
      console.debug('          isMileageDisabled =' + isMileageDisabled)
      console.debug('      readyForCalculation() =' + readyForCalculation())
      console.debug('--------------\n')

      baseRequest = {
        priceSource: !template?.priceSource ? undefined : (template!.priceSource as PriceSource),
        contractProductType: (template as IContractTemplateResponse)?.v4ProductType || null,
        type: 'EXTERNAL', // Will be overwrtitten
        isAdjustment: flow === 'ADJUST',
        contractTemplateId: template ? template.id : 0,
        value: realValue,
        valueType: valueType,
        duration: duration.value,
        mileage: mileage.value,
        startDateISOString: parsedStartDateISOString,
        registrationDate:
          product && product.regDate
            ? formatDate(product.regDate, { rawFormat: 'yyyy-MM-dd' })
            : formatDate(new Date(), { rawFormat: 'yyyy-MM-dd' }),
        optionIds: options.map((option) => option.id),
        providerPayments,
        providerShare: providerShare.priceInclVat,
        adjustedFrom: prettyIdentifier,
        isDownpaymentDistributed: isDownpaymentDistributed,
        startMileage: startMileage,
        startValue: startValue,
        startValueType: startValueType,
        modelYear: 0,
        registrationNumber: (product && 'vin' in product && product.regNumber) || '',
        serviceVariantId: (template && template.serviceVariantId) || '',
      }

      if (freeContract) {
        if (readyForCalculation()) {
          const request: ICustomContractCalculationRequest = {
            ...baseRequest,
            type: 'CUSTOM',
            brandName: (product && product.brand && product.brand.name) || '',
            productModelName: product.model.name || '',
            fuelTypeName: (product && product.fuelType && product.fuelType.name) || '',
            amountPerPayment: payments.customAmountPrPayment,
          }
          this.loadData(request)
        } else {
          this.lastSentCalcRequest = null
        }
      } else {
        if (readyForCalculation()) {
          let request:
            | IStandardContractCalculationRequest
            | IStandardAxContractCalculationRequest
            | IStandardV4PricingToolContractCalculationRequest

          switch (template!.priceSource) {
            case 'Pricelist':
              console.info('case: Pricelist')
              request = {
                ...baseRequest,
                type: 'STANDARD',
                brandId: product!.brand.id!,
                productModelId: product!.model.id!,
                fuelTypeId: 'vin' in product ? product!.fuelType.id! : 0,
              }
              break
            case 'Autoexperten':
              console.info('case: Autoexperten')
              request = {
                ...baseRequest,
                type: 'STANDARD',
                brandName: product && product.brand && product.brand.name ? product.brand.name : '',
                productModelName: product && product.model && product.model.name ? product.model.name : '',
                fuelTypeName: product && product.fuelType && product.fuelType.name ? product.fuelType.name : '',
              }
              break
            case 'V4PricingTool':
              console.info('case: V4PricingTool')
              if (!contract.template?.v4ProductId) {
                throw new Error(
                  'PriceCalculation is missing v4ProductId in template: ' + !contract.template?.v4ProductId,
                )
              }

              request = {
                ...baseRequest,
                type: 'STANDARD',
                vin: vin,
                serviceVariantId: '',
                v4ProductId: contract.template.v4ProductId,
                fuelTypeName: product && product.fuelType && product.fuelType.name ? product.fuelType.name : '',
                vehicleInfo: vehicleToVehicleInfo(contract.vehicleAlongItsContracts as VehicleAlongItsContracts),
              }
              break
          }

          this.loadData(request)
        } else {
          // Reset payments
          const initialValues = { ...contractFlowInitialState.payments }
          this.props.onChange({ payments: initialValues })
          this.lastSentCalcRequest = null
        }
      }
    } catch (err) {
      // Send error log to Sentry (only in production).
      const env = CONFIG.env
      const errorEventToSentry: SentryEvent = {
        message: 'An exception happend in calculatePrice(..) in class ContractFlowPayments',
        level: Severity.Error,
        environment: env,
        stacktrace: (err as any).stack || err,
        extra: {
          file: 'src/components/admin/Contract/Flow/Payments/index.tsx',
          url: process.env.PUBLIC_URL,
          prettyIdentifier: prettyIdentifier,
          userRole: userRole,
          isFreeContract: freeContract,
          contractType: baseRequest?.type,
          providerId: template?.providerId,
          templateInternalName: template?.name,
          templateExternalName: template?.description,
          templateId: template?.id,
          priceSource: template?.priceSource,
          serviceVariantId: template?.serviceVariantId,
          product: product,
        },
      }
      if (env === 'production') {
        captureEvent(errorEventToSentry)
      } else {
        console.error(errorEventToSentry)
        console.trace()
      }
    }
  }
}

export default compose<TProps, IOwnProps>(withHiddenVAT, withStyles(styles))(ContractFlowPayments)
